import React from "react";
import styles from "./Covid.module.scss";
import cx from "classnames";
import MaskImage from "../../../images/covid-mask.png";
import CameraImage from "../../../images/covid-camera.png";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../seo";
import { RouteLinks } from "../../../utils/route-links";

const query = graphql`
  {
    seo: markdownRemark(
      fields: { category: { eq: "seo" }, relativePath: { eq: "seo__covid" } }
    ) {
      frontmatter {
        metaTitle
        metaDescription
        metaKeywordList
        metaImageName
      }
    }
  }
`;

const Covid = () => {
  const queryResult = useStaticQuery(query);

  const {
    metaTitle,
    metaDescription,
    metaKeywordList,
    metaImageName,
  } = queryResult.seo.frontmatter;

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywordList}
        imageUrl={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        pageUrl={RouteLinks.covid}
      />
      <main className={cx("column", styles.mainContent)} data-test-e2e="covid-container">
      <section className={cx("column", styles.imgColumn)}>
        <figure className={styles.imgColumn__image}>
          <img src={MaskImage} alt="Covid Precaution" />
        </figure>
        <h3 className="d4">Client’s Responsibilities</h3>
        <ul>
          <li>
            Bring your own mask(s). Masks should be worn whenever possible
          </li>
          <li>Immediate family only</li>
          <li>Use provided hand sanitizer upon entering studio</li>
        </ul>
      </section>
      <section className={styles.imgColumn}>
        <div className={styles.imgColumn__image}>
          <img src={CameraImage} alt="Covid Precaution" />
        </div>
        <h3 className="d4">Photographer’s Responsibilities</h3>
        <ul>
          <li>Provide hand sanitizer</li>
          <li>Wear proper PPE as required per session</li>
          <li>Deep cleaning of studio and all props between each session</li>
          <li>Atleast 3 days between each studio session</li>
          <li>Regular Covid-19 testing</li>
        </ul>
      </section>
    </main>
    </>
  );
};

export default Covid;
