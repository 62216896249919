import React from "react";
import styles from "./covid.module.scss";
import BlogLayout from "../../blog-layout/BlogLayout";
import BlogHeader from "../../blog-header/BlogHeader";
import CovidComponent from "../../../commons/covid";

const Covid2 = () => {
  return (
    <BlogLayout>
      <BlogHeader>
        <div className={styles.header}>
          <h1 className="d2">Covid | Healthy &amp; Safety Practices</h1>
          <p className="p2">
            If you or someone in your family is experiencing any symptoms of
            Covid-19 please contact to reschedule
          </p>
        </div>
      </BlogHeader>
      <CovidComponent />
    </BlogLayout>
  );
};

export default Covid2;
