import React from "react";
import Covid1 from "../components/v1/pages/covid";
import Covid2 from "../components/v2/pages/covid";

const Covid = () => {
  if (process.env.GATSBY_VERSION === "v1") {
    return <Covid1 />;
  } else {
    return <Covid2 />;
  }
};

export default Covid;
